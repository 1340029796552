import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicService {

  
  constructor(private http: HttpClient) { }

  public sayHello(): Observable<string> {
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });       
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*'); 

    return this.http.get<string>(environment.apiUrl + "/hello", { headers: headers });
  }

  public getDataCurrentDay(sentit): Observable<any>
  {    
    const headers = new HttpHeaders({
      //authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });       
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*'); 
    
    return this.http.get<any>(environment.apiUrl + '/public/stats/currentDayByDirectionWithoutNulls/' + sentit, {headers: headers});
  }

  public getTotalsCurrentDay(sentit): Observable<any>
  {        
    const headers = new HttpHeaders({
      //authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });       
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*'); 
    
    return this.http.get<any>(environment.apiUrl + '/public/stats/currentDayTotalByNacionalityWithoutNulls/' + sentit, {headers: headers});
  }

  public getTotalsCurrentDayBySentitAndBorder(sentit, borderLine): Observable<any>
  {        
    const headers = new HttpHeaders({
      //authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });       
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*'); 
    
    return this.http.get<any>(environment.apiUrl + '/public/stats/currentDayTotalByNacionalityWithoutNulls/' + sentit + '/' + borderLine, {headers: headers});
  }
  
}
