export class DatasetTransformer {

    constructor(){
        
    }

    static transformUselessZerosToNaNInData(dataset: any, currentHour: number): any{
        let index = currentHour + 1

        for(let i = 0; i < dataset.length; i++){
            for(let j = index; j < dataset[i].data.length; j++){
                dataset[i].data[j] = NaN
            }
        }

        return dataset;
    }

    static setStyle(dataset: any): any {
        for(let i = 0; i < dataset.length; i++){
            dataset[i].borderWidth = 2;
            dataset[i].pointBorderWidth = 1;
        }

        let lastIndex = dataset[dataset.length - 1].data.length - 1
        if(dataset[dataset.length - 1].label != "No Iden."){
            dataset[dataset.length - 1].data[lastIndex] = 0
            dataset[dataset.length - 1].data[lastIndex - 1] = 0;
        }

        return dataset;
    }
}