import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public datepipe: DatePipe) { }
  
  @Input() time = "";
  @Input() typeView:Number = 0;
  @Input() updated_at = "";
  year = ""
  
  ngOnInit(): void {
    this.year = this.datepipe.transform(new Date(), 'yyyy');
  }

}
