<div class="not-found d-flex justify-content-center">
    <div class="d-flex justify-content-center align-items-center">
        <div class="col-6 text-center">
            <a routerLink="/menu"><img src="../../../assets/images/logo.png" class="w-50"></a>
        </div>
        <div class="col-6">
            <p class="not-found-title">404 Error - Not Found</p>
            <p class="not-found-description">Ups! Estàs intentant accedir a una pàgina que no existeix.</p>
        </div>
    </div>
</div>
