<footer class="container-fluid bg-dark">
    <div *ngIf="typeView == 0">
        <div class="row mt-1 mt-md-2 mb-1">
            <div class="col" *ngIf="updated_at != ''; else emptySpace">
                <span class="ml-0 ml-md-4">Actualitzat: {{updated_at}}</span> 
            </div>
            <div class="col" *ngIf="time != ''; else emptySpace">
                <span class="ml-0 ml-md-4">{{time}} </span> 
            </div>
            <div class="col col-md-4 text-right"> 
                <span class="mr-0 mr-md-4"> Copyright © {{year}} - AERCO - All Rights Reserved </span>
            </div>
        </div>  
    </div>
    
    <div *ngIf="typeView == 4">
        <div class="row mt-1 mt-md-2 mb-1 typeView-4">
            <div class="col" *ngIf="updated_at != ''; else emptySpace">
                <span class="ml-0 ml-md-4">Actualitzat: {{updated_at}}</span> 
            </div>
            <div class="col" *ngIf="time != ''; else emptySpace">
                <span class="ml-0 ml-md-4">{{time}} </span> 
            </div>
            <div class="col col-md-4 text-right"> 
                <span class="mr-0 mr-md-4"> Copyright © {{year}} - AERCO - All Rights Reserved </span>
            </div>
        </div>  
    </div>

    <ng-template #emptySpace>
        <span class="col"></span>
    </ng-template>
</footer>

