import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(private http: HttpClient) { }

  public getDataByRange(ids, start, end, aggregationIntervalInSeconds): Observable<any>
  {
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });    
    
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');

    const body = { deviceId: ids,
                  start: start,
                  end: end,
                  aggregationIntervalInSeconds: aggregationIntervalInSeconds };
    
    return this.http.post<any>(environment.apiUrl + '/stats/totalByNacionalityAndDate', body, {headers: headers});
  }

  public getTotalsByDay(ids, start, end): Observable<any>
  {
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });

    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');
        
    const body = { deviceId: ids,
                  start: start,
                  end: end
                  };

    return this.http.post<any>(environment.apiUrl + '/stats/totalByNacionalityAndDay', body, {headers: headers});
    
  }
  
  public getDataCurrentDay(sentit): Observable<any>
  {
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });    
    
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');
    
    return this.http.get<any>(environment.apiUrl + '/stats/currentDayByDirection/' + sentit, {headers: headers});
  }

    
  public getDataCurrentDayByDevice(device): Observable<any>
  {
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });       
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*'); 
    
    return this.http.get<any>(environment.apiUrl + '/stats/currentDayByDevice/' + device, {headers: headers});
  }

  public getTotalsByTypeCurrentDay(sentit): Observable<any>
  {
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });        
    
    return this.http.get<any>(environment.apiUrl + '/ocr/byType/' + sentit + '/8', {headers: headers});
  }

  public getTotalsByMarcaCurrentDay(sentit): Observable<any>
  {
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });    
    
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');
    
    return this.http.get<any>(environment.apiUrl + '/ocr/byMarca/' + sentit + '/5', {headers: headers});
  }

  public getTotalsByNacionalityCurrentDay(sentit): Observable<any>
  {
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });        
    
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');

    return this.http.get<any>(environment.apiUrl + '/ocr/byNacionality/' + sentit + '/10', {headers: headers});
  }

  
  public getVehiclesTotalsByNacionalityCurrentDay(sentit): Observable<any>
  {
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });        
    
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*');
    
    return this.http.get<any>(environment.apiUrl + '/stats/currentDayTotalByNacionality/' + sentit, {headers: headers});
  }

  public getTotalsCurrentDayBySentitAndBorder(sentit, borderLine): Observable<any>
  {        
    const headers = new HttpHeaders({
      authorization : 'Basic ' + btoa(localStorage.getItem("usuari") + ':' + localStorage.getItem("contrasenya"))
    });       
    headers.append('Access-Control-Allow-Headers', 'Content-Type');
    headers.append('Access-Control-Allow-Methods', 'GET');
    headers.append('Access-Control-Allow-Origin', '*'); 
    
    return this.http.get<any>(environment.apiUrl + '/stats/currentDayTotalByNacionality/' + sentit + '/' + borderLine, {headers: headers});
  }
}
