<div style="padding: 20px;">
    <div [ngClass]="{'dark-mode-container': isDarkModeActive == true}">             

        <div class="row">                 
            <div class="col text-center" [ngClass]="{'dark-mode-container': isDarkModeActive == true}">
                DADES EN TEMPS REAL D'ENTRADA I SORTIDA DEL PAÍS    
            </div>
        </div>
        
        <div>
            <div class="row">
                <div class="col-lg-6 col" style="padding: 5px;">
                    <div class="col chart-container">
                        <div class="text-center mb-3" [ngClass]="{'dark-mode-container': isDarkModeActive == true}">ENTRADA DE VEHICLES PER HORA I NACIONALITAT</div>
                        <canvas #chartLeft="base-chart" baseChart
                            [datasets]="lineChartDataEntries" 
                            [labels]="lineChartLabels" 
                            [options]="lineChartOptions"
                            [colors]="lineChartColors" 
                            [legend]="lineChartLegend" 
                            [chartType]="lineChartType" 
                            [plugins]="lineChartPlugins">
                        </canvas>                
                    </div>   

                    <div class="col" style="background-color: #26293B;">
                        <app-counter-boxes type="1" direction="1"></app-counter-boxes>
                    </div>
                </div>

                <div class="col-lg-6 col" style="margin-bottom: 20px;padding: 5px;">
                    <div class="col chart-container">
                        <div class="text-center mb-3" [ngClass]="{'dark-mode-container': isDarkModeActive == true}">SORTIDA DE VEHICLES PER HORA I NACIONALITAT</div>
                        <canvas #chartRight="base-chart" baseChart
                            [datasets]="lineChartDataExits" 
                            [labels]="lineChartLabels" 
                            [options]="lineChartOptions"
                            [colors]="lineChartColors" 
                            [legend]="lineChartLegend" 
                            [chartType]="lineChartType" 
                            [plugins]="lineChartPlugins">
                        </canvas>                    
                    </div>

                    <div class="col" style="background-color: #26293B;">
                        <app-counter-boxes type="1" direction="0"></app-counter-boxes>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>

<app-footer [updated_at]=updated_at></app-footer>