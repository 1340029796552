import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  @Output() credentials = null;
  authenticated = false;
  title = 'comptadors';


  constructor(private router: Router)
  {  }

  ngOnInit()
  {
  }

  loadData(credentials)
  {
    this.authenticated = true;
    this.credentials = credentials;    
  }

}
