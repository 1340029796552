import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import {DatePipe} from '@angular/common';
import { NgApexchartsModule } from 'ng-apexcharts';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { E404Component } from './errors/e404/e404.component';
import { FooterComponent } from './footer/footer.component';
import { CounterBoxesComponent } from './component/counter-boxes/counter-boxes.component';
import { CounterBoxComponent } from './component/counter-box/counter-box.component';
import { CounterTotalBoxComponent } from './component/counter-total-box/counter-total-box.component';
import { ChartDashboardComponent } from './stats/external/chart-dashboard/chart-dashboard.component';

@NgModule({
  declarations: [
    AppComponent,
    E404Component,
    FooterComponent,
    CounterBoxComponent,
    CounterBoxesComponent,
    CounterTotalBoxComponent,
    ChartDashboardComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ChartsModule,
    NgApexchartsModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
