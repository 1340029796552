import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { ChartNg2Helper } from 'src/app/helpers/ChartNg2Helper';
import { DatasetTransformer } from 'src/app/helpers/DatasetTransformer';
import { PublicService } from '../../../services/public.service';


@Component({
  selector: 'app-chart-dashboard',
  templateUrl: './chart-dashboard.component.html',
  styleUrls: ['./chart-dashboard.component.scss']
})
export class ChartDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
  isDarkModeActive = true;
  isPublicView = true;
  timerId = null;
  updated_at = "";
  currentDay = "";
  maxValue = 200;
  lineChartDataEntries: ChartDataSets[] = [

  ];

  lineChartDataExits: ChartDataSets[] = [

  ];

  lineChartLabels: Label[] = [];
  
  public lineChartOptions = {
    responsive: true,        
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,          
          display:false
      },
       {
        scaleLabel: {
          fontColor:'#AAA',
         },
        gridLines: {
          color: '#777'
      },
      ticks: {
         fontColor: "#AAA",
         fontSize: 14
        }
       },
      ],
      yAxes: [
       {
        scaleLabel: {
         display: true,
         labelString: "Nº Vehicles",
         fontColor:'#AAA',
        },
        gridLines: {
          color: '#777'
        },
        ticks: {
           fontColor: "#AAA",
           fontSize: 14,
           max : this.maxValue,
           min : 0,
          }        
       }       
      ],
     },
     pan: {
      enabled: true,
      mode: 'y'
    },
    /*elements: {
        line: {
                fill: false
        }
    },*/    
    zoom: {
        enabled: true,
        mode: 'y',
        limits: {
            max: 10,
            min: 5
        }
    },
    legend: {
      display: true,
      labels: {
        fontColor: '#AAA', // legend color (can be hexadecimal too)
      },
    }
  };

  lineChartColors: Color[] = [
    {            
      borderColor: '#ffa600dd'
    },
    {            
      borderColor: '#FF4C4CDD'
    },
    {            
      borderColor: '#70D085DD'
    },
    {            
      borderColor: '#bf47aeDD'
    }
  ];

  lineChartLegend = false;
  lineChartPlugins = [];
  lineChartType = 'line';
  isLoadingData = 0;


  @ViewChild("chartLeft") chartLeft: BaseChartDirective;
  @ViewChild("chartRight") chartRight: BaseChartDirective;

  constructor(private publicService:PublicService, public datepipe: DatePipe, private elementRef: ElementRef) { 
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = "#1e1e2f";
  }

  
  ngAfterViewInit() {
    //console.log(this.chartLeft.getChartConfiguration());
    //console.log(this.chartLeft.getChartConfiguration().options.scales.yAxes[0].ticks.max=700);
  }


  ngOnInit(): void {
    
    this.loadData();
    this.timerId = setInterval(() => this.loadData(), 5*60*1000);
  }

  loadData()
  {   
    this.currentDay = this.datepipe.transform(new Date(), 'dd-MM-yyyy');
    this.updated_at = this.datepipe.transform(new Date(), 'dd-MM-yyyy HH:mm');
    
    this.isLoadingData = 2;    
    //Entrada
    this.publicService.getDataCurrentDay(1).subscribe(
      (response) => {
        this.lineChartOptions.scales.yAxes[0].ticks.max = Math.max(this.lineChartOptions.scales.yAxes[0].ticks.max, response.maxValue); 
        this.lineChartDataEntries  = [...DatasetTransformer.transformUselessZerosToNaNInData(response.dataset, new Date(Date.now()).getHours() + 1)];
        this.lineChartDataEntries = [...DatasetTransformer.setStyle(response.dataset)];
        this.isLoadingData = this.isLoadingData-1;     
        this.chartLeft = ChartNg2Helper.refresh(this.chartLeft);
        this.chartRight = ChartNg2Helper.refresh(this.chartRight);    
      },
      (error : HttpErrorResponse) => {
        console.log(error.message);
      }
    );

    //Sortida
    this.publicService.getDataCurrentDay(0).subscribe(
      (response) => {
        this.lineChartOptions.scales.yAxes[0].ticks.max = Math.max(this.lineChartOptions.scales.yAxes[0].ticks.max, response.maxValue); 
        this.lineChartLabels = response.labels;
        this.lineChartDataExits= [...DatasetTransformer.transformUselessZerosToNaNInData(response.dataset, new Date(Date.now()).getHours() + 1)]
        this.lineChartDataExits = [...DatasetTransformer.setStyle(response.dataset)];
        this.isLoadingData = this.isLoadingData-1;      
        this.chartLeft = ChartNg2Helper.refresh(this.chartLeft);
        this.chartRight = ChartNg2Helper.refresh(this.chartRight)         
      },
      (error : HttpErrorResponse) => {
        console.log(error.message);
      }
    );    
  }

  updateTheme(event){
    this.isDarkModeActive = event;
  }

  ngOnDestroy(): void
  {
    clearInterval(this.timerId);
  }
}


