<div class="card-total">
    <div class="card-total-content">
        <div class="main-title">
            <span>TOTAL DIA</span>
        </div>
        <div class="text-lg">
            <span>{{ total | number:'':'es'}}</span>
        </div>
        <div class="main-title" style="color: #CCC; margin-top: 0px;">
            <span>vehicles</span>
        </div>
    </div>
</div>