<div class="row" style="background-color: #26293B;">
    <ng-container *ngIf="items.length > 4">
        <app-counter-total-box class="col-xl-4 col-lg-2 col-md-12" [direction]="direction" [total]="totalValue"></app-counter-total-box>

        <app-counter-box class="col-xl-4 col-lg-2 col-md-6" *ngFor="let item2 of items" style="padding: 5px;" 
            [mainTitle]="item2.nationality"
            [percentageVehicles]="item2.percentage"
            [numVehicles]="item2.numVehicles"
            [percentageBorderEs]="item2.borderEs[0]"
            [percentageBorderFr]="item2.borderFr[0]"
            [numVehiclesBorderEs]="item2.borderEs[1]"
            [numVehiclesBorderFr]="item2.borderFr[1]"
            [colorTop]="item2.colorBorderTop">
        </app-counter-box>
    </ng-container>

    <ng-container *ngIf="items.length == 4">
        <app-counter-total-box class="col-xl-2 col-lg-2 col-md-12" [direction]="direction" [total]="totalValue"></app-counter-total-box>

        <app-counter-box class="col-xl col-lg col-md-6" *ngFor="let item2 of items" style="padding: 5px;" 
            [mainTitle]="item2.nationality"
            [percentageVehicles]="item2.percentage"
            [numVehicles]="item2.numVehicles"
            [percentageBorderEs]="item2.borderEs[0]"
            [percentageBorderFr]="item2.borderFr[0]"
            [numVehiclesBorderEs]="item2.borderEs[1]"
            [numVehiclesBorderFr]="item2.borderFr[1]"
            [colorTop]="item2.colorBorderTop">
        </app-counter-box>
    </ng-container>
</div>