import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { E404Component } from './errors/e404/e404.component';
import { ChartDashboardComponent } from './stats/external/chart-dashboard/chart-dashboard.component';

const routes: Routes = [
  {path:'', component:ChartDashboardComponent},
  {path:'stats/external/dashboard', component:ChartDashboardComponent},
  {path:'**', component:E404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
