import { Component, Input, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-counter-box',
  templateUrl: './counter-box.component.html',
  styleUrls: ['./counter-box.component.scss']
})
export class CounterBoxComponent implements OnInit {

  @Input("mainTitle") mainTitle: string = "";
  @Input("percentageVehicles") percentageVehicles: number = 0;
  @Input("numVehicles") numVehicles: number = 0;
  @Input("percentageBorderEs") percentageBorderEs: number = 0;
  @Input("percentageBorderFr") percentageBorderFr: number = 0;
  @Input("numVehiclesBorderEs") numVehiclesBorderEs: number = 0;
  @Input("numVehiclesBorderFr") numVehiclesBorderFr: number = 0;

  @Input("colorTop") colorTop: string = "#26293B";

  constructor() { }

  ngOnInit(): void {
    registerLocaleData(es);
  }


}
