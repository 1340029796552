import { BaseChartDirective } from "ng2-charts";

export class ChartNg2Helper {

    constructor(){

    }

    static refresh(chart: BaseChartDirective){
        chart.ngOnDestroy();
        if(chart !== undefined) chart.chart = chart.getChartBuilder(chart.ctx);
        return chart;
    }
} 