<div class="card-box">
    <div [ngStyle]="{'border-top': '3px solid ' + colorTop }"></div>
    <div class="card-content">
        <div class="card-content-text-lg">
            <span>{{ mainTitle }}</span>
        </div>
        <div class="fontSizePercentage">
            <span>{{ percentageVehicles | number:'1.1-2':'es'}} %</span>
        </div>
        <div class="text-wrap fontSizeVehicles">
            <span>{{ numVehicles | number:'':'es'}} veh.</span>
        </div>

        <div class="card-content-chart">
            <div class="card-content-chart-description">
                <span>Frontera ES</span>
                <span>Frontera FR</span>
            </div>
            <div class="card-content-chart-design">
                <div class="card-content-chart-left" [title]="numVehiclesBorderEs + ' veh.'"
                    [ngStyle]="{'width': percentageBorderEs +'%' }" ></div>
                <div class="card-content-chart-right" [title]="numVehiclesBorderFr + ' veh.'"
                    [ngStyle]="{'width': percentageBorderFr +'%' }"></div>
            </div>
            <div class="card-content-chart-value">
                <span>{{ percentageBorderEs | number:'1.1-2':'es'}} %</span>
                <span>{{ percentageBorderFr | number:'1.1-2':'es'}} %</span>
            </div>
        </div>
    </div>
</div>