import { Component, Input, OnInit } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';

@Component({
  selector: 'app-counter-total-box',
  templateUrl: './counter-total-box.component.html',
  styleUrls: ['./counter-total-box.component.scss']
})
export class CounterTotalBoxComponent implements OnInit {

  @Input("direction") direction: number = 0;
  @Input("type") type: number = 0;
  @Input("total") total: number = 0;

  constructor() { }

  ngOnInit(): void {
    registerLocaleData(es);
  }
}