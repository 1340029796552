import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { counterBoxItem } from 'src/app/models/counterBoxItem.model';
import { PublicService } from 'src/app/services/public.service';
import { StatsService } from 'src/app/stats/stats.service';

enum DataSourceType {
  PUBLIC = 1,
  PRIVATE = 2
}

@Component({
  selector: 'app-counter-boxes',
  templateUrl: './counter-boxes.component.html',
  styleUrls: ['./counter-boxes.component.scss']
})
export class CounterBoxesComponent implements OnInit {

  items: counterBoxItem[] = [];

  @Input("type") type: number = 0; 
  @Input("direction") direction: number = 0;

  totalValue: number = 0;
  timerId = null;

  colors: String[] = ['#ffa600dd', '#FF4C4CDD', '#70D085DD', '#bf47aeDD', '#5856bfDD'];


  constructor(private statsService: StatsService, 
    private publicService: PublicService) { }

  ngOnInit(): void {
    this.initItems();
    if(this.type == DataSourceType.PUBLIC){
      this.loadPublicData();
      this.timerId = setInterval(() => this.loadPublicData(), 5*60*1000);
    }else if(this.type == DataSourceType.PRIVATE){
      this.loadPrivateData();
      this.timerId = setInterval(() => this.loadPrivateData(), 5*60*1000);
    }
  }

  loadPublicData(): void {
    this.publicService.getTotalsCurrentDay(this.direction).subscribe(
      (response) => {
        this.prepareDataToDisplayForTotals(response);
    },
    (error: HttpErrorResponse) => {
      console.log(error.message);
    });

    this.getTotalsCurrentDayBySentitAndBorder(0);
    this.getTotalsCurrentDayBySentitAndBorder(1);
  }

  loadPrivateData(): void {
    this.statsService.getVehiclesTotalsByNacionalityCurrentDay(this.direction).subscribe((response) => {
      this.prepareDataToDisplayForTotals(response);
    },
    (error: HttpErrorResponse) => {
      console.log(error.message);
    });

    this.getTotalsCurrentDayBySentitAndBorderPrivate(0);
    this.getTotalsCurrentDayBySentitAndBorderPrivate(1);
  }

  private initItems(): void {
    this.items = [];
    let limit = this.type == 1 ? 4 : 5;
    for(let i = 0; i < limit; i++){
      let item = new counterBoxItem();
      item.nationality = "";
      item.percentage = 0;
      item.numVehicles = 0;
      item.borderEs = [];
      item.borderFr = [];
      item.colorBorderTop = "#000000";
      this.items.push(item);
    }
  }

  private writeTotalsInItems(index: number, nationality: string, percentage: number, totals: number){
    if(this.items.length <= index || index < 0) return;
    this.items[index].nationality = nationality;
    this.items[index].percentage = percentage;
    this.items[index].numVehicles = totals;
    this.items[index].colorBorderTop = this.colors[index].toString();
  }

  private writeDataBorderInItems(index: number, typeBorder: number, border: Array<number>): void {
    if(this.items.length <= index || index < 0) return;
    if(typeBorder == 0){
      this.items[index].borderEs = border;
    }else{
      this.items[index].borderFr = border;
    }
  }

  private getTotalsCurrentDayBySentitAndBorder(borderLine: number): void {
    this.publicService.getTotalsCurrentDayBySentitAndBorder(this.direction, borderLine).subscribe((response) => {
      this.writeDataBorderInItems(0, borderLine, [response.AD.percentge, response.AD.value]);
      this.writeDataBorderInItems(1, borderLine, [response.FR.percentge, response.FR.value]);
      this.writeDataBorderInItems(2, borderLine, [response.ES.percentge, response.ES.value]);
      this.writeDataBorderInItems(3, borderLine, [response.Altres.percentge, response.Altres.value]);
    });
  }

  private getTotalsCurrentDayBySentitAndBorderPrivate(borderLine: number): void {
    this.statsService.getTotalsCurrentDayBySentitAndBorder(this.direction, borderLine).subscribe((response) => {
      this.writeDataBorderInItems(0, borderLine, [response.AD.percentge, response.AD.value]);
      this.writeDataBorderInItems(1, borderLine, [response.FR.percentge, response.FR.value]);
      this.writeDataBorderInItems(2, borderLine, [response.ES.percentge, response.ES.value]);
      this.writeDataBorderInItems(3, borderLine, [response.Altres.percentge, response.Altres.value]);
      if(response.hasOwnProperty("No Iden."))
        this.writeDataBorderInItems(4, borderLine, [response["No Iden."].percentge, response["No Iden."].value]);
    })
  }

  private prepareDataToDisplayForTotals(data: any): void {
    if (data.hasOwnProperty("ES"))
      this.totalValue = data.AD + data.FR + data.ES + data.Altres;
    else   
      this.totalValue = 0;

    if (data.hasOwnProperty("No Iden.")) 
      this.totalValue += data["No Iden."];

    if (this.totalValue == 0) 
    {
      this.writeTotalsInItems(0, "ANDORRANS", 0, 0);
      this.writeTotalsInItems(1, "FRANCESOS", 0, 0);
      this.writeTotalsInItems(2, "ESPANYOLS", 0, 0);
      this.writeTotalsInItems(3, "ALTRES", 0, 0);
      if (data.hasOwnProperty("No Iden.")) 
        this.writeTotalsInItems(4, "No Iden.", 0, 0);

      return;
    } 

    var percentatgeAD = (Math.round(data.AD*100/this.totalValue*10)/10);
    var percentatgeFR= (Math.round(data.FR*100/this.totalValue*10)/10);
    var percentatgeES = (Math.round(data.ES*100/this.totalValue*10)/10);
    var percentatgeALTRES = (Math.round(data.Altres*100/this.totalValue*10)/10);
    var percentatgeNOIDEN = 0;
    if (data.hasOwnProperty("No Iden.")) percentatgeNOIDEN = (Math.round(data["No Iden."]*100/this.totalValue*10)/10);
   
    var totalPercentatge = percentatgeAD + percentatgeFR + percentatgeES + percentatgeALTRES + percentatgeNOIDEN;

    if (totalPercentatge > 100) percentatgeALTRES = percentatgeALTRES - (totalPercentatge-100);

    this.writeTotalsInItems(0, "ANDORRANS", percentatgeAD, data.AD);
    this.writeTotalsInItems(1, "FRANCESOS", percentatgeFR, data.FR);
    this.writeTotalsInItems(2, "ESPANYOLS", percentatgeES, data.ES);
    this.writeTotalsInItems(3, "ALTRES", percentatgeALTRES, data.Altres);

    if (data.hasOwnProperty("No Iden.")) 
      this.writeTotalsInItems(4, "No Iden.", percentatgeNOIDEN, data["No Iden."]);

  }
}
